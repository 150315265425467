<script>
import moment from 'moment';
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import DatePicker from "vue2-datepicker";
import 'vue2-datepicker/locale/pt-br';
import SettingService from '@/services/settings'
import ProductService from '@/services/product'
import { VMoney } from 'v-money'

moment.locale('pt-BR');

export default {
  page: {
    title: "Campanha - Cupons de Desconto",
  },
  components: { Layout, DatePicker, PageHeader },
  directives: { money: VMoney },
  data() {
    return {
      title: "Campanha - Cupons de Desconto",
      loading: false,
      coupons: [],
      coupon: {},
      departments: [],
      modalError: '',
      couponToRemove: '',

      searchValue: '',
      searchActive: false,
      count: 0,
      currentCount: 0,
      currentPage: 1,
      totalPages: 0,
      perPage: 0,
      startIndex: 0,
      endIndex: 0,

      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      fields: [
        { key: 'code', sortable: false, label: 'Código' },
        { key: 'discount', sortable: false, label: '% Desconto', class: 'nowrap-col', formatter: (value) => {
          return `${value.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} %`
        } },
        { key: 'startDate', sortable: false, label: 'Data Início', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY');
        } },
        { key: 'endDate', sortable: false, label: 'Data Final', formatter: (value) => {
          return moment(value).format('DD/MM/YYYY');
        } },
        { key: 'countSales', sortable: false, label: 'Utilizações' },
        { key: 'actions', label: 'Ações', class: 'nowrap-col' }
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load(showLoading = true, page = 1, term = '') {
      let loader;

      if (showLoading) {
        loader = this.$loading.show();
      }

      try {
        const departmentsResult = await ProductService.getDepartments();
        this.departments = departmentsResult.data;

        const couponsResult = await SettingService.getCoupons(term, page, this.searchActive);
        this.coupons = couponsResult.data.coupons;

        this.count = couponsResult.data.count;
        this.currentCount = couponsResult.data.currentCount;
        this.currentPage = couponsResult.data.currentPage;
        this.totalPages = couponsResult.data.totalPages;
        this.perPage = couponsResult.data.perPage;
        this.startIndex = couponsResult.data.startIndex;
        this.endIndex = couponsResult.data.endIndex;

        if (showLoading) {
          loader.hide();
        }
      } catch (err) {
        if (showLoading) {
          loader.hide();
        }
        this.$toast.error('Ocorreu um erro ao carregar as configurações');
      }
    },
    async saveCoupon() {
      this.modalError = '';

      const data = {
        ...this.coupon,
        discount: Number(this.coupon.discount.toString().replace('.', '').replace(',', '.')),
      }

      if (!this.coupon.code) {
        return this.modalError = 'Informe o código do cupom';
      }

      if (!this.coupon.startDate || !this.coupon.endDate > 0) {
        return this.modalError = 'Informe o período de validade do cupom';
      }

      if (!data.discount) {
        return this.modalError = 'Informe o percentual do desconto corretamente';
      }

      this.$bvModal.hide('modal-edit');

      let loader = this.$loading.show();

      try {
        if (this.coupon._id) {
          await SettingService.updateCoupon(data);
        } else {
          await SettingService.createCoupon(data);
        }

        loader.hide();

        this.load(false);

        this.$toast.open('Dados atualizados com sucesso');
      } catch (err) {
        loader.hide();
        
        if (err && err.response.data.code && err.response.data.code === 'E9999') {
          return this.$toast.warning(err.response.data.msg);
        }

        this.$toast.error('Ocorreu um erro inesperado');
      }
    },
    onPageChange() {
      window.scrollTo(0,0);
      this.load(true, this.currentPage);
    },
    search() {
      this.searchEmptyField = false,
      this.load(true, 1, this.searchValue);
    },
    changeSearch() {
      if (this.searchValue === '' && !this.searchEmptyField) {
        this.searchEmptyField = true;
        this.load(true, 1, this.searchValue);
      }
    },
    newCoupon() {
      this.coupon = {
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    editCoupon(item) {
      this.coupon = {
        _id: item._id,
        code: item.code,
        startDate: new Date(item.startDate),
        endDate: new Date(item.endDate),
        discount: item.discount.toFixed(2).replace('.', ','),
      }

      this.modalError = '';
      this.$bvModal.show('modal-edit');
    },
    removeCoupon() {
      this.$bvModal.hide('modal-remove');

      SettingService.removeCoupon(this.couponToRemove).then(() => {
        this.load(false);
        this.$toast.open('Cupom removido com sucesso');
      }).catch(() => {
        this.$toast.error('Ocorreu um erro ao remover o cupom');
      })
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mb-2 align-items-center">
              <div class="col-sm-8 col-lg-6">
                <div class="mr-2 mb-2">
                  <div class="position-relative search-input">
                    <form @submit.prevent="search" class="align-items-center">
                      <input
                        type="text"
                        class="form-control"
                        v-model="searchValue"
                        v-on:keyup="changeSearch"
                        placeholder="Pesquisar por código do cupom"
                      />

                      <button type="submit" class="btn btn-primary" v-if="!loading">
                        Pesquisar
                      </button>

                      <a href="javascript:void(0);" class="text-primary" v-if="loading">
                        <b-spinner class="ml-2" variant="primary" role="status"></b-spinner>
                      </a>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-sm-4 col-lg-6">
                <div class="text-sm-right">
                  <button type="button" class="btn btn-primary btn-rounded mb-2 mr-2" @click="newCoupon()">
                    <i class="mdi mdi-plus mr-1"></i> Novo Cupom  
                  </button>
                </div>
              </div>
            </div>  

            <div class="row mb-4">
              <div class="col">
                <div class="custom-control custom-checkbox">
                  <input type="checkbox" class="custom-control-input" id="searchActive" v-model="searchActive" />
                  <label class="custom-control-label" for="searchActive">Mostrar apenas cupons ativos</label>
                </div>
              </div>
            </div>

            <div>
             
              <b-table
                show-empty
                emptyText="Nenhum registro para exibir"
                :items="coupons"
                :fields="fields"
                responsive="sm"
                head-variant="light"
              >
                <template v-slot:cell(actions)="row">
                 

                  <a href="javascript:void(0);" class="action-icon text-success mr-3" @click="editCoupon(row.item)">
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a href="javascript:void(0);" class="action-icon text-danger" v-b-modal.modal-remove @click="couponToRemove = row.item._id" >
                    <i class="mdi mdi-close-circle-outline font-size-18"></i>
                  </a>
                </template>
              </b-table>

              <div class="row justify-content-md-between align-items-md-center mt-4">
                <div class="col-xl-7">Mostrando {{startIndex}} - {{endIndex}} de {{count}}</div>
                <!-- end col-->
                <div class="col-xl-5">
                  <div class="text-md-right float-xl-right mt-2 pagination-rounded">
                    <b-pagination
                      v-model="currentPage" 
                      :total-rows="count"
                      :per-page="perPage"
                      @input="onPageChange"
                    ></b-pagination>
                  </div>
                </div>
                <!-- end col-->
              </div>
            </div>
          </div>
          <!-- end card-body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <b-modal id="modal-edit" :title="coupon._id ? 'Alterando Cupom' : 'Novo Cupom'" centered>
      <b-alert v-if="modalError" v-model="modalError" variant="danger" dismissible>{{modalError}}</b-alert>

      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label for="code">Código do cupom</label>

            <input
              id="code"
              name="code"
              type="text"
              class="form-control"
              v-model="coupon.code"
            />
          </div>
        </div>

        <div class="col-sm-6">
          <div class="form-group">
            <label for="productStock">% Desconto</label>

            <input
              id="discount"
              name="discount"
              type="tel"
              class="form-control"
              v-money="money"
              v-model="coupon.discount"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group mb-3">
            <label>Período de</label>
            <date-picker v-model="coupon.startDate" :first-day-of-week="1" format="DD/MM/YYYY" lang="pt-br"></date-picker>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group mb-3">
            <label>até</label>
            <date-picker v-model="coupon.endDate" :first-day-of-week="1" format="DD/MM/YYYY" lang="pt-br"></date-picker>
          </div>
        </div>
      </div>
      
      <template v-slot:modal-footer>
        <b-button variant="link" @click="$bvModal.hide('modal-edit')">Cancelar</b-button>
        <b-button variant="primary" @click="saveCoupon()">
          Gravar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-remove" title="Excluir Cupom" title-class="font-18" centered>
      <p>Confirma a exclusão do cupom?</p>

      <div slot="modal-footer">
        <b-btn variant="link" @click="$bvModal.hide('modal-remove')">Cancelar</b-btn>
        <b-btn variant="primary" @click="removeCoupon">Excluir</b-btn>
      </div>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .search-input form {
    display: flex;
    flex-direction: row;

    .btn {
      margin-left: 10px;
    }
  }

  .form-control:disabled {
    background-color: #e6e6e6;
  }
</style>